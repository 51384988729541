import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const TermsAndConditions = () => {
  return (
    <Container className="container mx-auto px-4 py-8">
      <div>
        <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>

        <p>
          This document is an electronic record under the Information Technology
          Act, 2000, and the applicable rules. It adheres to provisions
          regarding electronic records in various statutes as amended by the
          Information Technology Act, 2000. Being generated by a computer
          system, it requires no physical or digital signatures.
        </p>
        <p>
          This document is published in compliance with Rule 3 (1) of the
          Information Technology (Intermediaries Guidelines) Rules, 2011, which
          mandates the publication of rules, regulations, privacy policy, and
          terms of use for access or usage of the domain name{" "}
          <strong>{window.location.hostname}</strong> ("Website"), including
          related mobile sites and mobile applications (collectively referred
          to as "Platform").
        </p>

        <h2>1. Platform Ownership</h2>
        <p>
          The Platform is owned by <strong>{window.location.hostname}</strong>{" "}
          and managed by <strong>{process.env.REACT_APP_FAM}</strong>, with its
          registered address at:
        </p>
        <p>
          <strong>{process.env.REACT_APP_ADDRESS}</strong>
        </p>
        <p>
          Hereafter, the terms "Platform Owner," "we," "us," or "our" refer to{" "}
          <strong>{window.location.hostname}</strong> and its management.
        </p>

        <h2>2. Acceptance of Terms</h2>
        <p>
          By accessing or using the Platform, you agree to be bound by these
          terms and conditions ("Terms of Use"), including applicable policies
          referenced herein. These Terms constitute a legally binding agreement
          between you ("User," "you," or "your") and the Platform Owner.
        </p>

        <h2>3. Use of the Platform</h2>
        <ul>
          <li>
            <strong>Lawful Usage:</strong> You agree to use the Platform only
            for lawful purposes and in a manner that does not infringe the
            rights of others or restrict or inhibit their use and enjoyment.
          </li>
          <li>
            <strong>Prohibited Activities:</strong> Engaging in any harmful,
            disruptive, or malicious activities that could impair the
            functionality or security of the Platform is prohibited.
          </li>
        </ul>

        <h2>4. Intellectual Property</h2>
        <p>
          All content, including but not limited to text, graphics, logos,
          images, and software, is the property of the Platform Owner or its
          licensors. Unauthorized reproduction, distribution, or use of the
          content is prohibited.
        </p>

        <h2>5. Privacy Policy</h2>
        <p>
          Our privacy practices are outlined in the Privacy Policy, which is
          incorporated by reference into these Terms. By using the Platform,
          you consent to the collection, use, and sharing of your information
          as described in the Privacy Policy.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          We are not liable for any damages, including direct, indirect,
          incidental, or consequential damages, arising from your use of the
          Platform or its services. This limitation applies to the fullest
          extent permitted by law.
        </p>

        <h2>7. Changes to the Terms</h2>
        <p>
          We reserve the right to modify these Terms of Use at any time without
          prior notice. Continued use of the Platform after any changes
          constitutes your acceptance of the revised terms.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These Terms are governed by the laws of India, and any disputes will
          be subject to the exclusive jurisdiction of the courts in Anand,
          Gujarat.
        </p>

        <p>
          By using our Platform, you acknowledge that you have read,
          understood, and agree to abide by these Terms and Conditions.
        </p>
      </div>

      <div className="text-center mt-6">
        <h3
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#727272",
            textAlign: "center",
          }}
        >
          Get Our Newsletter
        </h3>
        <p>Subscribe to receive updates, access to exclusive deals, and more.</p>
        <Form
          method="post"
          action="/contact#footer-newsletter"
          id="footer-newsletter"
          acceptCharset="UTF-8"
          className="my-3"
        >
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="utf8" value="✓" />
          <input type="hidden" name="contact[tags]" value="newsletter" />
          <Form.Control
            size="lg"
            type="email"
            placeholder="Enter email"
            className="mb-3"
          />
          <Button
            type="submit"
            variant="dark"
            className="mt-3"
            style={{
              background: "var(--them-color)",
              borderColor: "var(--them-color)",
            }}
          >
            Subscribe
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
